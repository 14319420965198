class ApiManager {
  private static readonly instances = new Map<new () => any, any>()

  public static getInstance<T>(ApiClientClass: new () => T): T {
    if (!ApiManager.instances.has(ApiClientClass)) {
      ApiManager.instances.set(ApiClientClass, new ApiClientClass())
    }
    return ApiManager.instances.get(ApiClientClass) as T
  }
}

export default ApiManager
