/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VerificationRequestDto
 */
export interface VerificationRequestDto {
    /**
     * User email
     * @type {string}
     * @memberof VerificationRequestDto
     */
    email: string;
    /**
     * OTP
     * @type {number}
     * @memberof VerificationRequestDto
     */
    otp: number;
    /**
     * Role to verify
     * @type {string}
     * @memberof VerificationRequestDto
     */
    type: VerificationRequestDtoTypeEnum;
}


/**
 * @export
 */
export const VerificationRequestDtoTypeEnum = {
    RoleGiver: 'ROLE_GIVER',
    RoleTaker: 'ROLE_TAKER',
    RolePreSignup: 'ROLE_PRE_SIGNUP',
    Anonymous: 'ANONYMOUS',
    RoleForgotPassword: 'ROLE_FORGOT_PASSWORD',
    Admin: 'ADMIN'
} as const;
export type VerificationRequestDtoTypeEnum = typeof VerificationRequestDtoTypeEnum[keyof typeof VerificationRequestDtoTypeEnum];


/**
 * Check if a given object implements the VerificationRequestDto interface.
 */
export function instanceOfVerificationRequestDto(value: object): value is VerificationRequestDto {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('otp' in value) || value['otp'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function VerificationRequestDtoFromJSON(json: any): VerificationRequestDto {
    return VerificationRequestDtoFromJSONTyped(json, false);
}

export function VerificationRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerificationRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'otp': json['otp'],
        'type': json['type'],
    };
}

export function VerificationRequestDtoToJSON(value?: VerificationRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'otp': value['otp'],
        'type': value['type'],
    };
}

