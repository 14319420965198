import { type RtmChannel } from 'agora-rtm-react'

interface MessageType {
  type: string
  message: Record<string, any>
}

export const sendMessage = async (session: RtmChannel, message: MessageType): Promise<void> => {
  try {
    const jsonString = JSON.stringify(message)
    await session.sendMessage({ text: jsonString })
  } catch (error) {
    console.error('Error sending message:', error)
  }
}

export const receiveMessage = (text: string): MessageType | null => {
  try {
    const message: MessageType = JSON.parse(text)
    console.log(message)
    return message
  } catch (error) {
    return null
  }
}
