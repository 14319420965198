/* eslint-disable */
/**
 * API Documentation
 * The API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  InterviewJoiningRequestDto,
  InterviewJoiningResponseDto,
} from '../models/index';
import {
    InterviewJoiningRequestDtoFromJSON,
    InterviewJoiningRequestDtoToJSON,
    InterviewJoiningResponseDtoFromJSON,
    InterviewJoiningResponseDtoToJSON,
} from '../models/index';

export interface InterviewVideoControllerJoinRequest {
    interviewJoiningRequestDto: InterviewJoiningRequestDto;
}

/**
 * 
 */
export class InterviewVideoControllerApi extends runtime.BaseAPI {

    /**
     * Join to interview
     */
    async interviewVideoControllerJoinRaw(requestParameters: InterviewVideoControllerJoinRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InterviewJoiningResponseDto>> {
        if (requestParameters['interviewJoiningRequestDto'] == null) {
            throw new runtime.RequiredError(
                'interviewJoiningRequestDto',
                'Required parameter "interviewJoiningRequestDto" was null or undefined when calling interviewVideoControllerJoin().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/interview/join`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InterviewJoiningRequestDtoToJSON(requestParameters['interviewJoiningRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewJoiningResponseDtoFromJSON(jsonValue));
    }

    /**
     * Join to interview
     */
    async interviewVideoControllerJoin(requestParameters: InterviewVideoControllerJoinRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InterviewJoiningResponseDto> {
        const response = await this.interviewVideoControllerJoinRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
