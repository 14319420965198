// import AgoraRTC, { AgoraRTCProvider, useRTCClient } from 'agora-rtc-react'
import React from 'react'
// import { AgoraManager } from './agora/AgoraManager'
// import config from './agora/config'
// import { useLocation } from 'react-router-dom'
import InterviewProcess from '../components/VideoChat/InterviewProcess/InterviewProcess'
// import Signals from './agora/Signals'
// import VideoWithOverlay from './mpd_player/DashVideoPlayer'
//
const AgoraVideoCall: React.FC = () => {
  // const agoraEngine = useRTCClient(AgoraRTC.createClient({ codec: 'vp8', mode: 'rtc' }))
  // const [joined, setJoined] = useState(false)
  // const location = useLocation()
  // const queryParams = new URLSearchParams(location.search)
  // const tempId: string = queryParams.get('tempId') ?? ''
  // const handleJoinClick = () => {
  //   setJoined(true)
  // }
  //
  // const handleLeaveClick = () => {
  //   setJoined(false)
  // }

  // const renderActionButton = () => {
  //   return joined ? (
  //     <button onClick={handleLeaveClick}>Leave</button>
  //   ) : (
  //     <button onClick={handleJoinClick}>Join</button>
  //   )
  // }

  return (
    <div>
      {/* <h1>Get Started with Video Calling</h1> */}
      {/* {renderActionButton()} */}
      <InterviewProcess/>
      {/* {joined && ( */}
      {/*   // <AgoraRTCProvider client={agoraEngine}> */}
      {/*   //   <AgoraManager config={config} tempId={tempId}> */}
      {/*   //     <></> */}
      {/*   //   </AgoraManager> */}
      {/*   // </AgoraRTCProvider> */}
      {/* )} */}
      {/* <Signals/> */}
      {/* <div> */}
      {/*   <h1>Video Player with Custom Overlay</h1> */}
      {/*   <VideoWithOverlay/> */}
      {/* </div> */}
    </div>
  )
}

export default AgoraVideoCall
