import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { NavigationRoute } from './enumeration/NavigationRoute'
import AdminPage from './pages/AdminPage'
import CreateEditInterviewTypePage from './pages/CreateEditInterviewTypePage'
import AllInterviewTypesPage from './pages/AllInterviewTypesPage'
import TakerDetailsPage from './pages/TakerDetailsPage'
import AllTakersListPage from './pages/AllTakersListPage'
import Auth from './util/Auth'
import AdPage from './pages/AdPage'
import WelcomePage from './pages/WelcomePage'
import SignUpPage from './pages/SignUpPage'
import LoginPage from './pages/LoginPage'
import ContactUsPage from './pages/ContactUsPage'
import InterviewReviewPage from './components/VideoChat/InterviewReviewPage'
import InterviewFeedbackPage from './components/VideoChat/InterviewFeedbackPage'
// import VideoChatPage from './components/VideoChat/VideoChatPage'
import HomePage from './pages/HomePage'
import InterviewCards from './giver/InterviewCards'
import HistoryList from './giver/HistoryList'
import TakerInterviewCards from './components/TakerProfil/TakerInterviewCards'
import { ProfilePage } from './pages/ProfilePage'
import NotFoundPage from './pages/NotFoundPage'
import AboutUsPage from './pages/AboutUsPage'
import AbsoluteUpcomingCard from './components/absoluteUpcomingCard/AbsoluteUpcomingCard'
import SettingsPage from './pages/settings/SettingsPage'
import TestPage from './pages/TestPage'
import CreateEditInterviewBalanceCardPage from './pages/CreateEditInterviewBalanceCardPage'
import CreateEditInterviewBalanceCardTagPage from './pages/CreateEditInterviewBalanceCardTagPage'
import AllBalanceCardTagsListPage from './pages/AllBalanceCardTagsListPage'
import AllBalanceCardListPage from './pages/AllBalanceCardListPage'
import { VerificationRequestDtoTypeEnum as AuthRole } from './api/ic'
import PrivacyPolicyPage from './pages/PrivacyPolicyPage'
import InterviewQuestionInsertionPage from './pages/InterviewQuestionInsertionPage'
import InterviewRecordingPage from './components/VideoChat/InterviewRecordingPage'
import InterviewProcess from './components/VideoChat/InterviewProcess/InterviewProcess'

function App () {
  const isAd = localStorage.getItem('isAd') !== 'false' // TODO temp logics
  return (
    <div>
      <Routes>
        <Route path={NavigationRoute.TEST_PAGE} element={<TestPage/>}/>
        <Route path={NavigationRoute.PRIVACY_POLICY} element={<PrivacyPolicyPage/>}/>

        <Route element={
          <Auth allowedRoles={[AuthRole.Admin]}/>}>
          <Route path={NavigationRoute.ADMIN} element={<AdminPage/>}/>
          <Route path={NavigationRoute.ADMIN_ADD_INTERVIEW_TYPE} element={<CreateEditInterviewTypePage/>}/>
          <Route path={NavigationRoute.ADMIN_EDIT_INTERVIEW_TYPE} element={<CreateEditInterviewTypePage/>}/>
          <Route path={NavigationRoute.ADMIN_SEE_ALL_INTERVIEWS} element={<AllInterviewTypesPage/>}/>
          <Route path={NavigationRoute.ADMIN_TAKER_DETAILS} element={<TakerDetailsPage/>}/>
          <Route path={NavigationRoute.ADMIN_SEE_ALL_TAKER} element={<AllTakersListPage mode={'all'}/>}/>
          <Route path={NavigationRoute.ADMIN_SEE_ALL_REQUESTED_TAKER} element={<AllTakersListPage mode={'requested'}/>}/>
          <Route path={NavigationRoute.ADMIN_SEE_ALL_REJECTED_TAKER} element={<AllTakersListPage mode={'rejected'}/>}/>
          <Route path={NavigationRoute.ADMIN_ADD_INTERVIEW_BALANCE_CARD_TAG} element={<CreateEditInterviewBalanceCardTagPage/>}/>
          <Route path={NavigationRoute.ADMIN_EDIT_INTERVIEW_BALANCE_CARD_TAG} element={<CreateEditInterviewBalanceCardTagPage/>}/>
          <Route path={NavigationRoute.ADMIN_SEE_ALL_INTERVIEW_BALANCE_CARD_TAGS} element={<AllBalanceCardTagsListPage/>}/>
          <Route path={NavigationRoute.ADMIN_ADD_INTERVIEW_BALANCE_CARD} element={<CreateEditInterviewBalanceCardPage/>}/>
          <Route path={NavigationRoute.ADMIN_EDIT_INTERVIEW_BALANCE_CARD} element={<CreateEditInterviewBalanceCardPage/>}/>
          <Route path={NavigationRoute.ADMIN_SEE_ALL_INTERVIEW_BALANCE_CARDS} element={<AllBalanceCardListPage/>}/>
          <Route path={NavigationRoute.ADMIN_INTERVIEW_QUESTION_INSERTION} element={<InterviewQuestionInsertionPage/>}/>
        </Route>

        <Route element={
          <Auth allowedRoles={[
            AuthRole.RoleGiver,
            AuthRole.RoleTaker,
            AuthRole.Admin]}/>}>
          <Route path={NavigationRoute.SETTINGS} element={<SettingsPage/>}/>
        </Route>

        <Route element={
          <Auth allowedRoles={[
            AuthRole.Anonymous,
            AuthRole.RolePreSignup,
            AuthRole.RoleForgotPassword]}/>}>
          <Route path={NavigationRoute.DEFAULT} element={isAd ? <AdPage/> : <WelcomePage/>}/>
          <Route path={NavigationRoute.SIGN_UP} element={<SignUpPage/>}/>
          <Route path={NavigationRoute.LOG_IN} element={<LoginPage/>}/>
          <Route path={NavigationRoute.CONTACT_US} element={<ContactUsPage/>}/>
        </Route>

        <Route element=
          {<Auth
            allowedRoles={[AuthRole.RoleGiver, AuthRole.RoleTaker]}
            additionalComponent={<AbsoluteUpcomingCard/>}
            exclude={[NavigationRoute.TAKE_INTERVIEW]}/>}>
          <Route path={NavigationRoute.INTERVIEW_JOIN} element={<InterviewProcess/>}/>
          <Route path={NavigationRoute.INTERVIEW_REVIEW} element={<InterviewReviewPage/>}/>
          <Route path={NavigationRoute.INTERVIEW_FEEDBACK} element={<InterviewFeedbackPage/>}/>
          <Route path={NavigationRoute.INTERVIEW_RECORDING} element={<InterviewRecordingPage/>}/>
          {/* <Route path={NavigationRoute.INTERVIEW} element={<VideoChatPage/>}/> */}
          <Route path={NavigationRoute.HOME_PAGE} element={<HomePage/>}>
            <Route index element={<InterviewCards/>}/>
            <Route path={NavigationRoute.INTERVIEW_HISTORY.replace('/', '')} element={<HistoryList/>}/>
          </Route>
          <Route path={NavigationRoute.PROFILE} element={<ProfilePage/>}/>
        </Route>

        <Route element=
          {<Auth
            allowedRoles={[AuthRole.RoleTaker]}
            additionalComponent={<AbsoluteUpcomingCard/>}
            exclude={[NavigationRoute.TAKE_INTERVIEW]}/>}>
          <Route path={NavigationRoute.HOME_PAGE} element={<HomePage/>}>
            <Route path={NavigationRoute.TAKE_INTERVIEW.replace('/', '')} element={<TakerInterviewCards/>}/>
          </Route>
        </Route>

        <Route path="*" element={<NotFoundPage/>}/>
        <Route path={NavigationRoute.ABOUT} element={<AboutUsPage/>}/>
      </Routes>

    </div>
  )
}

export default App
