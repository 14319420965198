import { type ColoredTextItemConfig } from '../components/text/ColoredText'
import { NavigationItemType } from '../enumeration/NavigationItemType'
import { NavigationRoute } from '../enumeration/NavigationRoute'
import { FontModeValues } from '../components/modes/FontMode'

export const COLORS = {
  MAIN_COLOR: '#8C0CF0',
  MAIN_COLOR_DARK: '#7000C7'
}

export const KEYBOARD_KEYS: Record<string, number> = {
  ENTER: 13
}

const year: number[] = []

for (let i = 2000; i <= 2024; i++) {
  year.push(i)
}

export const API_CLIENT_DATA: Record<string, string> = {
  HOST: process.env.REACT_APP_BACKEND_HOST ?? 'http://localhost:8085',
  VIDEO_HOST: process.env.REACT_APP_VIDEO_BACKEND_HOST ?? 'http://localhost:5050'
}

export const NAVIGATION_ITEMS = {
  ABOUT: {
    enumValue: NavigationItemType.ABOUT,
    link: NavigationRoute.ABOUT
  },
  CONTACT_US: {
    enumValue: NavigationItemType.CONTACT_US,
    link: NavigationRoute.CONTACT_US
  }
}

export const STATIC_TEXTS = {
  READ_ONLY_SECTION_INFO: 'This section is not editable as it was only for the approval process. If you need to make changes, please contact us.'
}

export const COLORED_TEXT_VALUES: Record<string, ColoredTextItemConfig[]> = {
  ABOUT_US_GIVER: [
    {
      value: 'Are you a career opportunity Giver, actively exploring',
      newLine: true,
      color: 'white'
    },
    {
      value: 'new paths? We\'re here to be your guide, connecting you',
      newLine: true,
      color: 'white'
    },
    {
      value: 'with platforms that align perfectly with your professional',
      newLine: true,
      color: 'white'
    },
    {
      value: 'goals. Let\'s navigate this journey together!',
      newLine: true,
      color: 'white'
    }
  ],
  ABOUT_US_TAKER: [
    {
      value: 'Are you a seasoned pro, ready to take on leadership roles',
      newLine: true,
      color: 'white'
    },
    {
      value: 'and share your wealth of knowledge?  Join our',
      newLine: true,
      color: 'white'
    },
    {
      value: 'community of Takers – where experience meets',
      newLine: true,
      color: 'white'
    },
    {
      value: "opportunity. Let's elevate our game together!",
      newLine: true,
      color: 'white'
    }
  ],
  WHY_INTERVIEW_CENTER: [
    {
      value: 'At Interview Center, our name is intentional. "Center"',
      newLine: true
    },
    {
      value: 'symbolizes the heart of professional exchange, ',
      newLine: true
    },
    {
      value: 'where interviews are opportunities. More than a',
      newLine: true
    },
    {
      value: 'typical platform, it\'s a hub where careers meet ',
      newLine: true
    },
    {
      value: 'conversations, and your journey shines. '
    },
    {
      value: 'Welcome to',
      color: COLORS.MAIN_COLOR,
      newLine: true
    },
    {
      value: 'the Interview Center',
      color: COLORS.MAIN_COLOR
    },
    {
      value: '– where your next career move',
      newLine: true
    },
    {
      value: 'is not just an interview, it\'s a transformative ',
      newLine: true
    },
    {
      value: 'experience.'
    }
  ],
  OUR_MISSION: [
    {
      value: 'Spark Brilliance at'
    },
    {
      value: 'Interview Center!',
      color: COLORS.MAIN_COLOR
    },
    {
      value: ' Join our friendly ',
      newLine: true
    },
    {
      value: 'mission of redefining careers and celebrating your ',
      newLine: true
    },
    {
      value: 'unique skills in a cosmic symphony! Beyond'
    },
    {
      value: 'CVs,',
      color: COLORS.MAIN_COLOR
    },
    {
      value: 'join',
      newLine: true
    },
    {
      value: 'the revolution!'
    }
  ],
  YOUR_STORY: [
    {
      value: 'Interview Center isn\'t just a tool; it\'s a fun ride. Your journey with us isn\'t just about getting a job; it\'s',
      newLine: true
    },
    {
      value: 'about telling your career story. Embrace a future where your skills, passion, and uniqueness are ',
      newLine: true
    },
    {
      value: 'celebrated',
      newLine: true
    },
    {
      value: '',
      newLine: true
    },
    {
      value: 'Ready to jump into a new way of hiring? Join Interview Center, where interviews are more like a ',
      newLine: true
    },
    {
      value: 'celebration of your talents. Let\'s shake up resumes and redefine careers together. Welcome to a ',
      newLine: true
    },
    {
      value: 'world beyond CVs.'
    },
    {
      value: 'Welcome to Interview Center.',
      color: COLORS.MAIN_COLOR
    }
  ],
  REVOLUTIONIZING_HIRING: [
    {
      value: 'We\'re not your typical website; we\'re stirring things up. Come along as we bid farewell to mundane',
      newLine: true
    },
    {
      value: 'CVs. Dive into interviews, witness talent unfold, and make hiring decisions based on your actual ',
      newLine: true
    },
    {
      value: 'abilities, not just what\'s written on a page. Our mission is clear: to revolutionize the way you ',
      newLine: true
    },
    {
      value: 'discover and connect with talent.',
      newLine: true
    }

  ],
  SYMPHONY_OF_QUESTIONS: [
    {
      value: 'Dive into interviews made just for you. '
    },
    {
      value: 'HR questions, tech stuff, brain teasers',
      color: COLORS.MAIN_COLOR
    },
    {
      value: '— we\'ve got it all. Each ',
      newLine: true
    },
    {
      value: 'interview is like your own show, recording the real you. It\'s not just about answering questions; it\'s ',
      newLine: true
    },
    {
      value: 'about showing the world how awesome you are.'
    }
  ],
  CONNECTING_TALENTS: [
    {
      value: 'Our mission is simple: let\'s ditch those boring '
    },
    {
      value: 'CVs.',
      color: COLORS.MAIN_COLOR
    },
    {
      value: 'Why? Because we believe your skills and',
      newLine: true
    },
    {
      value: 'personality can\'t fit on a piece of paper. Every interview on our platform is a special story,',
      newLine: true
    },
    {
      value: 'showing off your skills.'
    }
  ],
  HELP_YOU_SUCCEED: [
    {
      value: 'And guess what? We\'ve got Takers—friendly professionals ready to help you shine.',
      newLine: true
    },
    {
      value: 'Each Taker is an expert, here to share tips and help you put your best foot forward. ',
      newLine: true
    },
    {
      value: 'Your success isn\'t just a goal; it\'s a team effort.',
      newLine: true
    }
  ],
  EMPOWERING_INTERVIEWS: [
    {
      value: 'Anyone can be a Giver here. As a Giver, you\'re not just someone in an interview; you\'re a skill ',
      newLine: true
    },
    {
      value: 'champion. Learn new things, answer questions like a boss, and stand out in your interviews. ',
      newLine: true
    },
    {
      value: 'The best performances, chosen by '
    },
    {
      value: 'feedback and scores,',
      color: COLORS.MAIN_COLOR
    },
    {
      value: 'get the high-fives they deserve.'
    }
  ],
  SHAPING_TOMORROWS: [
    {
      value: 'At'
    },
    {
      value: 'Interview Center,',
      color: COLORS.MAIN_COLOR
    },
    {
      value: 'we\'re all about making your talents stand out. No more dealing',
      newLine: true
    },
    {
      value: 'with old resumes—our cool SaaS tool is your ticket to a hiring experience that\'s all',
      newLine: true
    },
    {
      value: 'about you'
    }
  ],
  ABOUT_US: [
    {
      value: 'Hey there! Welcome to'
    },
    {
      value: 'Interview Center,',
      color: COLORS.MAIN_COLOR
    },
    {
      value: 'where we\'re',
      newLine: true
    },
    {
      value: 'changing the way hiring works. In a world full of boring',
      newLine: true
    },
    {
      value: 'resumes and the same old cover letters, we\'re creating a new',
      newLine: true
    },
    {
      value: 'way—where your skills get the spotlight. Started in 2023,',
      newLine: true
    },
    {
      value: 'Interview Center isn\'t just a website; it\'s a cool place where',
      newLine: true
    },
    {
      value: 'CVs',
      color: COLORS.MAIN_COLOR
    },
    {
      value: 'are out, and your talent is in the spotlight.'
    }
  ],
  EVERY_STAR: [
    {
      value: 'Every star in our constellation is crucial, and your ',
      color: 'white'
    },
    {
      value: 'feedback',
      color: '#AEC3FF'
    },
    {
      value: ' is the ',
      color: 'white',
      newLine: true
    },
    {
      value: 'guiding light we value!',
      color: 'white'
    }
  ],
  INTERVIEW_SEARCHER_TEXT: [
    {
      value: 'Time Locked In, Explorer!',
      color: COLORS.MAIN_COLOR,
      newLine: true
    },
    {
      value: 'Fantastic choice! Your selected day and time have been successfully submitted to our intergalactic scheduling system.',
      color: COLORS.MAIN_COLOR,
      newLine: true
    },
    {
      value: 'Get ready for your cosmic journey! We\'ll be in touch soon to provide further details.',
      color: COLORS.MAIN_COLOR
    }
  ],
  SHARE_YOUR_COSMIC: [
    {
      value: 'Share your cosmic thoughts with the "',
      color: 'white'
    },
    {
      value: 'Feedback Shuttle',
      color: '#AEC3FF'
    },
    {
      value: '"',
      color: 'white'
    }
  ],
  DONT_FORGET_FEEDBACK: [
    {
      value: 'Don\'t forget to share your thoughts – your',
      color: 'white'
    },
    {
      value: 'feedback',
      color: '#AEC3FF'
    },
    {
      value: 'fuels our journey!',
      color: 'white'
    }
  ],
  REVIEW_TEXT: [
    {
      value: 'Ready to Wrap Up? Conclude this interview ',
      newLine: true
    },
    {
      value: 'and step into your next adventure!'
    }
  ],
  WELCOME_TEXT: [
    {
      value: 'Give ',
      color: COLORS.MAIN_COLOR
    },
    {
      value: 'or '
    },
    {
      value: 'Take',
      color: COLORS.MAIN_COLOR,
      newLine: true
    },
    {
      value: 'Interviews today!'
    }
  ],
  HR_RECRUITER_DISCOVER: [
    {
      value: "If you're an"
    },
    {
      value: 'HR/Recruiter,',
      color: COLORS.MAIN_COLOR
    },
    {
      value: 'discover interviews with top talents!'
    }
  ],
  SEND_EMAIL_FOR_FREE_INTERVIEW: [
    {
      value: 'Send your email'
    },
    {
      value: 'Now',
      color: COLORS.MAIN_COLOR
    },
    {
      value: 'to get your'
    },
    {
      value: 'FREE',
      color: COLORS.MAIN_COLOR,
      fontWeight: 500
    },
    {
      value: ' interview,',
      color: COLORS.MAIN_COLOR,
      newLine: true
    },
    {
      value: 'and access top interviews.'
    }
  ],
  THANKS_FOR_JOINING: [
    {
      value: 'Thanks for'
    },
    {
      value: 'joining',
      color: COLORS.MAIN_COLOR
    },
    {
      value: 'us as a'
    },
    {
      value: 'Role',
      color: COLORS.MAIN_COLOR,
      stylePriority: true,
      parameterIndex: 0
    },
    {
      value: '!'
    }
  ],
  WANT_ANOTHER_ROLE_EMAIL: [
    {
      value: 'Want another'
    },
    {
      value: 'role',
      color: COLORS.MAIN_COLOR
    },
    {
      value: 'or'
    },
    {
      value: 'email',
      color: COLORS.MAIN_COLOR
    },
    {
      value: '?'
    }
  ],
  GET_STARTED_SMALL_TEXT: [
    {
      value: 'Journey into Discovery: Learn, Grow, Evolve. Join as an Interviewer or Interviewee,',
      color: 'rgba(0, 0, 0, 0.65)',
      newLine: true,
      fontFamily: 'Roberto,sans-serif',
      fontWeight: 500
    },
    {
      value: 'and explore a world of experiences and knowledge through interviews.',
      color: 'rgba(0, 0, 0, 0.65)',
      fontFamily: 'Roberto,sans-serif',
      fontWeight: 500
    }
  ],
  ALREADY_MEMBER: [
    {
      value: 'Already a member?',
      color: 'rgba(0, 0, 0, 0.85)',
      fontSize: FontModeValues.SMALL,
      fontWeight: 300
    },
    {
      value: 'Log in here.',
      color: COLORS.MAIN_COLOR,
      fontSize: FontModeValues.DEFAULT,
      fontWeight: 300,
      textDecoration: 'underline',
      cursor: 'pointer',
      onClick: () => {
        window.location.href = NavigationRoute.LOG_IN
      }
    }
  ],
  PRIVATE_PUBLIC: [
    {
      value: 'Public',
      color: COLORS.MAIN_COLOR,
      fontSize: FontModeValues.SMALL,
      fontWeight: 300
    },
    {
      value: ': Your interview can be viewed by recruiters and HRs',
      color: 'rgba(0, 0, 0, 0.65)',
      fontSize: FontModeValues.SMALL,
      fontWeight: 300,
      newLine: true
    },
    {
      value: 'Private',
      color: COLORS.MAIN_COLOR,
      fontSize: FontModeValues.SMALL,
      fontWeight: 300
    },
    {
      value: ': The interview is only visible to you.',
      color: 'rgba(0, 0, 0, 0.65)',
      fontSize: FontModeValues.SMALL,
      fontWeight: 300,
      newLine: true
    }
  ],
  SECURITY_TEXT: [
    {
      value: 'Your data security and privacy are our priority. Account governed by',
      color: 'rgba(0, 0, 0, 0.65)',
      newLine: true,
      fontFamily: 'Roboto,sans-serif',
      fontSize: FontModeValues.SMALL
    },
    {
      value: 'our',
      color: 'rgba(0, 0, 0, 0.65)',
      fontFamily: 'Roboto,sans-serif',
      fontSize: FontModeValues.SMALL
    },
    {
      value: 'Privacy&Policy',
      color: COLORS.MAIN_COLOR,
      fontFamily: 'Roboto,sans-serif',
      fontSize: FontModeValues.SMALL,
      cursor: 'pointer',
      textDecoration: 'underline',
      onClick: () => {
        window.open(NavigationRoute.PRIVACY_POLICY, '_blank', 'noopener,noreferrer')
      }
    },
    {
      value: 'and',
      color: 'rgba(0, 0, 0, 0.65)',
      fontFamily: 'Roboto,sans-serif',
      fontSize: FontModeValues.SMALL
    },
    {
      value: 'Terms&Conditions.',
      color: COLORS.MAIN_COLOR,
      fontFamily: 'Roboto,sans-serif',
      fontSize: FontModeValues.SMALL,
      textDecoration: 'underline',
      cursor: 'pointer',
      onClick: () => {
        window.open(NavigationRoute.PRIVACY_POLICY, '_blank', 'noopener,noreferrer')
      }
    }
  ],
  AD_PAGE_CONGRATS: [
    {
      value: 'You will get more about your'
    },
    {
      value: 'interviews',
      color: COLORS.MAIN_COLOR,
      newLine: true
    },
    {
      value: 'soon',
      color: COLORS.MAIN_COLOR
    },
    {
      value: 'to your'
    },
    {
      value: 'email@gmail.com',
      color: '#6000AA',
      parameterIndex: 0
    },
    {
      value: 'inbox',
      newLine: true
    },
    {
      value: '',
      newLine: true
    },
    {
      value: 'Explore world of'
    },
    {
      value: 'interviews',
      color: COLORS.MAIN_COLOR
    },
    {
      value: 'and be on the top!'
    }
  ],
  GET_STARTED: [
    {
      value: 'Get Started',
      color: '#FFF',
      fontWeight: 700,
      fontSize: '24px'
    },
    {
      value: "- It's Free",
      color: 'rgba(255, 255, 255, 0.85)',
      fontSize: '24px',
      fontWeight: 500
    }
  ],
  SYSTEM_ERROR_MESSAGE: [
    {
      value: 'Something went wrong while saving your data',
      newLine: true
    },
    {
      value: 'please try again!'
    }
  ],
  MAIL_EXAMPLE: [
    {
      value: 'Please enter the 6 digit code sent to',
      color: 'rgba(0, 0, 0, 0.65)',
      fontSize: FontModeValues.EXTRA_SMALL,
      newLine: true
    },
    {
      value: 'yourmail@example.com',
      color: 'rgba(0, 0, 0, 0.65)',
      fontSize: FontModeValues.EXTRA_SMALL,
      parameterIndex: 0
    }
  ],
  DONT_HAVE_ACCOUNT: [
    {
      value: 'Don\'t have an account yet? ',
      color: 'rgba(0, 0, 0, 0.85)',
      fontWeight: 300
    },
    {
      value: ' Sign Up.\n',
      color: COLORS.MAIN_COLOR,
      fontSize: FontModeValues.DEFAULT,
      fontWeight: 300,
      textDecoration: 'underline',
      cursor: 'pointer',
      onClick: () => {
        window.location.href = NavigationRoute.SIGN_UP
      }
    }
  ],
  PROFILE_PAGE_TEXT: [
    {
      value: 'Verified accounts open doors to impactful ',
      color: 'rgba(0, 0, 0, 1)',
      fontWeight: 400,
      newLine: true
    },
    {
      value: 'interviews',
      color: COLORS.MAIN_COLOR
    },
    {
      value: 'and showcase your',
      color: 'rgba(0, 0, 0, 1)'
    },
    {
      value: 'professionalism.',
      color: COLORS.MAIN_COLOR,
      newLine: true
    },
    {
      value: 'Don\'t miss out on this opportunity to stand out as.',
      color: 'rgba(0, 0, 0, 1)',
      newLine: true
    },
    {
      value: 'a trusted',
      color: 'rgba(0, 0, 0, 1)'
    },
    {
      value: 'expert.',
      color: COLORS.MAIN_COLOR
    }
  ],
  READ_PRIVACY: [
    {
      value: 'Please read our ',
      color: '#000',
      fontWeight: 400,
      fontFamily: 'Inter,sans-serif',
      fontSize: FontModeValues.EXTRA_SMALL
    },
    {
      value: 'Privacy',
      color: COLORS.MAIN_COLOR,
      fontWeight: 400,
      fontFamily: 'Inter,sans-serif',
      fontSize: FontModeValues.EXTRA_SMALL,
      cursor: 'pointer'
    },
    {
      value: 'before uploading ',
      color: '#000',
      fontWeight: 400,
      fontFamily: 'Inter,sans-serif',
      fontSize: FontModeValues.EXTRA_SMALL
    }
  ],
  CHAT_TO_US: [
    {
      value: 'Chat to us',
      newLine: true,
      fontWeight: 700,
      fontSize: '20px'
    },
    {
      value: 'We can\'t wait to hear from you – our ',
      newLine: true,
      fontSize: '16px'
    },
    {
      value: 'inbox is standing by, ready to be',
      newLine: true,
      fontSize: '16px'
    },
    {
      value: 'sprinkled with your magic!',
      newLine: true,
      fontSize: '16px'
    },
    {
      value: 'interviewcenter@gmail.com',
      fontWeight: 600,
      fontSize: '16px'
    }
  ],
  SOCIAL_MEDIA: [
    {
      value: 'Social media',
      newLine: true,
      fontWeight: 700,
      fontSize: '20px'
    },
    {
      value: 'Get in Touch and Elevate Your Experience!  Have',
      newLine: true
    },
    {
      value: 'questions, suggestions, or just want to chat? We\'re',
      newLine: true
    },
    {
      value: 'all ears! Drop us a message, your insights matter.',
      newLine: true
    },
    {
      value: '#GetInTouch #UserFirst',
      fontWeight: 600
    }
  ],
  SOCIAL_MEDIA_MOBILE: [
    {
      value: 'Social media',
      newLine: true,
      fontWeight: 700,
      fontSize: '20px',
      color: COLORS.MAIN_COLOR
    },
    {
      value: 'Get in Touch and Elevate Your Experience!',
      newLine: true
    },
    {
      value: 'Have questions, suggestions, or just want to ',
      newLine: true
    },
    {
      value: 'chat? We\'re all ears! Drop us a message, your',
      newLine: true
    },
    {
      value: ' insights matter.'
    },
    {
      value: '#GetInTouch #UserFirst',
      fontWeight: 600,
      color: COLORS.MAIN_COLOR
    }
  ],
  VERIFY_INFO: [
    {
      value: 'As you share personal details,',
      newLine: true
    },
    {
      value: 'rest assured that your data is in',
      newLine: true
    },
    {
      value: 'safe',
      color: COLORS.MAIN_COLOR
    },
    {
      value: 'hands. Take a moment to ',
      newLine: true
    },
    {
      value: 'provide '
    },
    {
      value: 'accurate ',
      color: COLORS.MAIN_COLOR
    },
    {
      value: 'information—',
      newLine: true
    },
    {
      value: 'it\'s an essential step in creating a',
      newLine: true
    },
    {
      value: 'trusted'
    },
    {
      value: 'professional',
      color: COLORS.MAIN_COLOR
    },
    {
      value: 'profile'
    }
  ],
  PICK_PICTURE: [
    {
      value: 'Pick a '
    },
    {
      value: 'profile',
      color: COLORS.MAIN_COLOR
    },
    {
      value: 'picture',
      newLine: true
    },
    {
      value: 'that everyone will see.',
      newLine: true
    },
    {
      value: 'Make it'
    },
    {
      value: 'count!',
      color: COLORS.MAIN_COLOR
    }
  ],
  ALMOST_THERE: [
    {
      value: 'You\'re Almost There!',
      newLine: true
    },
    {
      value: 'To seal the deal,'
    },
    {
      value: ' schedule a meeting ',
      color: COLORS.MAIN_COLOR
    },
    {
      value: 'with us',
      newLine: true
    },
    {
      value: 'for the final verification step. You\'ve got this!'
    }
  ],
  READY_TO_JOIN: [
    {
      value: 'Ready to join?',
      newLine: true,
      color: 'white',
      fontSize: FontModeValues.EXTRA_LARGE
    },
    {
      value: '',
      color: 'white',
      newLine: true
    },
    {
      value: 'Welcome to our interview platform – where success',
      color: 'white',
      newLine: true,
      fontSize: FontModeValues.SMALL
    },
    {
      color: 'white',
      value: 'meets opportunity! Get',
      fontSize: FontModeValues.SMALL
    },
    {
      value: 'camera and mic',
      color: 'rgba(174, 195, 255, 1)',
      fontSize: FontModeValues.SMALL

    },
    {
      color: 'white',
      value: 'ready for the',
      newLine: true,
      fontSize: FontModeValues.SMALL

    },
    {
      color: 'white',
      value: 'spotlight',
      fontSize: FontModeValues.SMALL

    }
  ]

}

export const DROPDOWN_VALUES: Record<string, string[] | number[]> = {
  ROLE: ['Giver',
    'Taker',
    'Giver & Taker',
    'HR/Recruiter'],
  COUNTRY: ['Armenia',
    'USA',
    'England'],
  GENDER: ['Male',
    'Female',
    'Other'],
  YEARS: year,
  URLS: ['Instagram', 'Twitter']
}

export const SINGLE_TEXT_VALUES: Record<string, ColoredTextItemConfig[]> = {
  COMPANY_NAME: [
    {
      value: '©2023 Interview Center',
      color: '#000',
      fontWeight: 300
    }
  ],
  SIGN_UP: [
    {
      value: 'Sign up',
      color: '#7000C7',
      fontSize: '70px',
      fontWeight: 700
    }
  ],
  CONTINUE_WITH_GOOGLE: [
    {
      value: 'Continue with Google',
      color: 'rgba(0, 0, 0, 0.54)',
      fontFamily: 'Roboto, sans-serif',
      fontSize: FontModeValues.SMALL,
      fontWeight: 500,
      whiteSpace: 'nowrap'
    }
  ],
  VERIFY_MAIL: [
    {
      value: 'Verify your email',
      color: '#000000',
      fontSize: FontModeValues.SMALL
    }
  ],
  LOG_IN: [
    {
      value: 'Log in',
      color: '#7000C7',
      fontSize: '70px',
      fontWeight: 700
    }
  ],
  FORGOT_PASSWORD: [
    {
      value: 'Forgot password',
      color: '#7000C7',
      fontSize: '40px',
      fontWeight: 700
    }
  ],
  CANCEL: [
    {
      value: 'Cancel',
      color: '#000',
      fontWeight: 400,
      fontSize: FontModeValues.DEFAULT,
      fontFamily: 'Inter,sans-serif',
      cursor: 'pointer'
    }
  ],
  GET_IN_TOUCH: [
    {
      value: 'Get in touch',
      color: '#880CE8',
      fontWeight: 700,
      fontSize: '36px'
    }
  ]
}

export const HINTS: Record<string, ColoredTextItemConfig[]> = {
  ROLE_DROPDOWN: [
    {
      value: 'This is just for quick-start',
      newLine: true
    },
    {
      value: 'Giver',
      color: COLORS.MAIN_COLOR
    },
    {
      value: '- you GIVE an interviews',
      newLine: true
    },
    {
      value: 'Taker',
      color: COLORS.MAIN_COLOR
    },
    {
      value: '- you TAKE an interviews',
      newLine: true
    },
    {
      value: 'Taker&Giver',
      color: COLORS.MAIN_COLOR
    },
    {
      value: '- you can TAKE and GIVE interviews',
      newLine: true
    },
    {
      value: 'HR/Recruiter',
      color: COLORS.MAIN_COLOR
    },
    {
      value: '- you want to find talents by watching best interviews',
      newLine: true
    }
  ],
  COUNTRY: [
    {
      value: 'Some Info About Country',
      newLine: true
    },
    {
      value: 'YEAH!',
      color: COLORS.MAIN_COLOR
    }
  ],
  DAY_HINT: [
    {
      value: 'Morning: ',
      color: COLORS.MAIN_COLOR
    },
    {
      value: ' 07:00AM-12:00PM',
      newLine: true
    },
    {
      value: 'Afternoon: ',
      color: COLORS.MAIN_COLOR
    },
    {
      value: ' 12:00PM-18:00PM',
      newLine: true
    },
    {
      value: 'Evening: ',
      color: COLORS.MAIN_COLOR
    },
    {
      value: ' 18:00PM-12:00AM',
      newLine: true
    },
    {
      value: 'Night: ',
      color: COLORS.MAIN_COLOR
    },
    {
      value: ' 12:00AM-07:00AM',
      newLine: true
    }
  ]

}
