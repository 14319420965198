import React, { useEffect, useState } from 'react'
import ColoredText from '../../text/ColoredText'
import { FontModeValues } from '../../modes/FontMode'
import StandardButton from '../../buttons/StandardButton'
import { ButtonModeValues } from '../../modes/ButtonMode'
import { Rating } from '@mui/material'
import StandardModal from '../../modal/StandardModal'
import {
  GetICUserDtoRoleEnum as ICUserRole,
  type GetInterviewQuestionDto,
  type GetInterviewQuestionTemplateDto,
  type GetInterviewTemplateSection, InterviewQuestionControllerApi
} from '../../../api/ic'
import ApiManager from '../../../manager/ApiManager'
import toast from 'react-hot-toast'
import { type IAgoraRTCRemoteUser } from 'agora-rtc-react'
import { type RtmTextMessage, type RtmClient, type RtmChannel } from 'agora-rtm-react'
import { receiveMessage, sendMessage } from './MessageHelperFunctions'

interface templateProps {
  template: GetInterviewQuestionTemplateDto
  client: RtmClient | null
  session: RtmChannel | null
  dontAskAgain?: boolean
  lastAnswerQuestion?: string
  type: ICUserRole
  subscriber?: IAgoraRTCRemoteUser | null
  interviewCompleted: boolean
  setInterviewCompleted: React.Dispatch<React.SetStateAction<boolean>>
}

function InterviewQuestionsTemplate (props: templateProps) {
  const [currentSection, setCurrentSection] = useState<GetInterviewTemplateSection | undefined>(undefined)
  const [currentQuestion, setCurrentQuestion] = useState<GetInterviewQuestionDto | undefined>(undefined)
  const [modalOpenedReason, setModalOpenedReason] = useState<'ASK_FOR_NEXT' | 'REVIEW_ERROR' | undefined>(undefined)
  const [modalContent, setModalContent] = useState<string>('')
  const [template, setTemplate] = useState<GetInterviewQuestionTemplateDto>(props.template)
  const interviewQuestionApi = ApiManager.getInstance(InterviewQuestionControllerApi)
  const [review, setReview] = useState<number | undefined>(undefined)
  const [anim, setAnim] = useState(false)
  const [dontAskAgain, setDontAskAgain] = useState(props.template.dontAskAgain)
  const [open, setOpen] = useState(false)
  const [receivedContent, setReceivedContent] = useState<string | undefined>(undefined)
  const [nextButtonLoading, setNextButtonLoading] = useState(false)

  useEffect(() => {
    if (props.session) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      props.session.on('ChannelMessage', (event: RtmTextMessage) => {
        const message = receiveMessage(event.text)
        if (message?.type === 'nextQuestion') {
          console.log('hasav namake')
          const parts = message.message.data.split('&&&&&')
          const questionId = parts[0].split('&&=&&')[1]
          const content = parts[1].split('&&=&&')[1]

          setReceivedContent(content)
          setTemplate({ ...template, currentQuestionId: questionId })
        }
        if (message?.type === 'interviewFinished') {
          console.log('hasav verjy')
          props.setInterviewCompleted(true)
        }
      })
    }
  }, [props.session])
  useEffect(() => {
    let found = false
    for (const section of template.sections) {
      for (const eachQuestion of section.questions) {
        if (eachQuestion.id === template.currentQuestionId) {
          if (receivedContent) {
            eachQuestion.content = receivedContent
          }
          setCurrentQuestion(eachQuestion)
          setCurrentSection(section)
          found = true
          break
        }
      }
      if (found) {
        break
      }
    }
    setAnim(false)
    setTimeout(() => {
      setAnim(true)
    }, 300)
  }, [template])

  const fireNextEvent = (questionDto: GetInterviewQuestionDto) => {
    if (props.session) {
      const data = `questionId&&=&&${questionDto.id}&&&&&content&&=&&${questionDto.content}`
      void sendMessage(props.session,
        { type: 'nextQuestion', message: { data } })
      const parts = data.split('&&&&&')
      const questionId = parts[0].split('&&=&&')[1]
      const content = parts[1].split('&&=&&')[1]

      setReceivedContent(content)
      setTemplate({ ...template, currentQuestionId: questionId })
    }
  }

  const fireFinishedEvent = () => {
    if (props.session) {
      void sendMessage(props.session, { type: 'interviewFinished', message: {} })
      props.setInterviewCompleted(true)
    }
  }

  const handleDontAskAgain = async () => {
    if (dontAskAgain !== template.dontAskAgain) {
      await interviewQuestionApi.dontAskForNext({
        interviewId: template.interviewId
      }).then(() => {
        setDontAskAgain(true)
        props.template.dontAskAgain = true
      }).catch(() => {
        toast.error('Could not update')
      })
    }
  }

  const nextQuestion = async () => {
    await handleDontAskAgain().then(() => {
      void interviewQuestionApi.next({
        interviewId: template.interviewId,
        interviewQuestionAnswerRequestDto: {
          points: review
        }
      }).then(res => {
        if (res.dto) {
          fireNextEvent(res.dto)
        } else if (res.completed) {
          fireFinishedEvent()
        }
        setOpen(false)
        setReview(0)
      }).catch(() => {})
        .finally(() => {
          setNextButtonLoading(false)
        })
    })
  }

  const checkForModalAndNext = async () => {
    setNextButtonLoading(true)
    // In case the review is required, open modal
    if (currentQuestion?.requiresReview && !review) {
      setOpen(true)
      setModalOpenedReason('REVIEW_ERROR')
      setModalContent('You need to review answer for this question!')
      setNextButtonLoading(false)
      return
    }

    // In case the state is AskAgain, open modal
    if (!dontAskAgain) {
      setOpen(true)
      setModalOpenedReason('ASK_FOR_NEXT')
      setModalContent('Once you go forward, there is no way back, be aware!')
      setNextButtonLoading(false)
      return
    }

    await nextQuestion()
  }

  const makeSectionQuestionCountText = (section: GetInterviewTemplateSection) => {
    let index = 0
    const isCurrentSection = section.type === currentSection?.type
    const targetSectionIndex = template.sections.findIndex(sec => sec.type === section?.type)
    const currentSectionIndex = template.sections.findIndex(sec => sec.type === currentSection?.type)

    if (targetSectionIndex < currentSectionIndex) {
      index = section.questions.length
    }

    if (isCurrentSection) {
      index = (section.questions.findIndex(q => q.id === currentQuestion?.id) + 1)
    }
    return `${index}/${section.questions.length}`
  }

  return (
    <div style={{ width: '500px' }} className={'d-flex flex-column gap-2'}>
      {template?.sections.map((section) => (
        <>
          <div className='d-flex flex-column' >
            <div className={'d-flex flex-row justify-content-between align-items-center'}
              style={{
                background: '#2E302F',
                borderRadius: '5px',
                borderBottomLeftRadius: section.type === currentSection?.type ? '0' : '5px',
                borderBottomRightRadius: section.type === currentSection?.type ? '0' : '5px',
                padding: '5px'
              }}>
              <ColoredText
                singleText={section.label}
                fontWeight={600}
                color={'white'}/>
              <ColoredText
                singleText={makeSectionQuestionCountText(section)}
                fontWeight={600}
                color={'white'}/>
            </div>
            <div className={'d-flex flex-column align-items-center gap-3'}
              style = {{
                transitionDuration: '0.5s',
                background: 'white',
                padding: section.type === currentSection?.type ? '40px' : '0px',
                display: section.type === currentSection?.type ? 'block' : 'none',
                height: section.type === currentSection?.type ? 'inherit' : '0px'
              }}>
              {section.type === currentSection?.type &&
                <div className={'d-flex flex-column align-items-center justify-content-center w-100'}>
                  <ColoredText
                    centered={true}
                    singleText={currentQuestion?.content}/>
                </div>}
              <div
                style={{
                  width: '100%',
                  overflowX: 'scroll'
                }}
                className={`d-flex flex-row ${currentQuestion?.requiresReview ? 'justify-content-between' : 'justify-content-end'} align-items-center`}>
                {props.type === ICUserRole.Taker && <>
                  {currentQuestion?.requiresReview && <div
                    style={{
                      display: section.type === currentSection?.type && anim ? 'flex' : 'none'
                    }}
                    className={'d-flex flex-row align-items-center justify-content-center gap-2'}>
                    <Rating
                      name={'simple-controlled'}
                      value={review || 0}
                      max={5}
                      style={{
                        display: section.type === currentSection?.type && anim ? 'flex' : 'none'
                      }}
                      size={'medium'}
                      onChange={(event, newValue) => {
                        setReview(newValue ?? undefined)
                        return event
                      }}/>
                    <ColoredText
                      fontSize={FontModeValues.LARGE}
                      fontWeight={500}
                      singleText={(review ?? '0') + '/5'}/>
                  </div>}
                  <StandardButton
                    backgroundColor={'#2E302F'}
                    sizeMode={ButtonModeValues.TINY_SMALL}
                    text={'Next'}
                    isLoading={nextButtonLoading}
                    clickable={props.subscriber !== null}
                    onClick={checkForModalAndNext}/>
                  </>}
              </div>
            </div>
          </div>
        </>
      ))}
      <StandardModal isOpen={open}
        closeWhenClickingOutOfModal={false}
        title={'Warning!'}
        titleColor={'black'}
        titleFontSize={FontModeValues.LARGE}
        needDividers={false}
        footerComponent={
          <div className={'w-100'}>
            {modalOpenedReason === 'REVIEW_ERROR' ? (
              <div className={'d-flex flex-row justify-content-end w-100'}>
                <StandardButton
                  text={'Okay'}
                  backgroundColor={'#2E302F'}
                  sizeMode={ButtonModeValues.TINY_SMALL}
                  onClick={() => {
                    setOpen(false)
                  }}/>
              </div>
            ) : (
              <div className={'d-flex flex-row justify-content-between align-items-center'} >
                <div
                  className={'d-flex flex-row justify-content-center gap-2'}
                  onClick={() => {
                    setDontAskAgain(!dontAskAgain)
                  }}>
                  <input
                    style={{ accentColor: '#2E302F' }}
                    type={'checkbox'}
                    checked={dontAskAgain}/>
                  <ColoredText
                    cursor={'pointer'}
                    singleText={'Don\'t ask again'}/>
                </div>
                <div className={'d-flex flex-row justify-content-center gap-2'}>
                  <StandardButton
                    text={'Wait'}
                    backgroundColor={'#2E302F'}
                    sizeMode={ButtonModeValues.TINY_SMALL}
                    onClick={() => {
                      setOpen(false)
                    }}/>
                  <StandardButton
                    text={'Next'}
                    backgroundColor={'rgba(46,48,47,0.71)'}
                    sizeMode={ButtonModeValues.TINY_SMALL}
                    onClick={nextQuestion}/>
                </div>
              </div>
            )}
          </div>
        }>
        <div className={'pt-3'}>
          <ColoredText
            centered={true}
            lineHeight={1}
            singleText={modalContent}/>
        </div>
      </StandardModal>
    </div>
  )
}

export default InterviewQuestionsTemplate
