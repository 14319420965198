import React, { useEffect, useState } from 'react'

interface Alert {
  time: number
  warningText: string
  action: () => void
}

interface InterviewTimeAlertsProps {
  timeStamp: number // This should represent the start time of the interview, not the current time
  alerts: Alert[]
}

const InterviewTimeAlerts = (props: InterviewTimeAlertsProps) => {
  const [currentAlert, setCurrentAlert] = useState<Alert | null>(null)
  const [nextAlert, setNextAlert] = useState<Alert | null>(null)
  const [allAlertsAlreadyShown, setAllAlertsAlreadyShown] = useState<boolean>(false)
  const [isCurrentAlertActionDone, setIsCurrentAlertActionDone] = useState<boolean>(false)
  const [showAlert, setShowAlert] = useState<boolean>(true)
  useEffect(() => {
    const sortedAlerts = [...props.alerts].sort((a, b) => a.time - b.time)
    const checkTime = () => {
      const now = new Date().getTime()
      const minutesPassed = (now - props.timeStamp) / (1000 * 60)
      const filteredAlerts = sortedAlerts.filter((alertobj: Alert) => alertobj.time > minutesPassed - 0.1)
      if (!filteredAlerts.length) {
        setAllAlertsAlreadyShown(true)
      }
      // Find the latest alert to trigger
      for (let i = 0; i < filteredAlerts.length; i++) {
        const alert = filteredAlerts[i]
        // Only trigger if the alert time has passed and it has not already been triggered
        if (minutesPassed >= alert.time) {
          if (alert.time !== currentAlert?.time) {
            setCurrentAlert(alert) // Update current alert index to show
            setShowAlert(true)
            setIsCurrentAlertActionDone(false)
            if (filteredAlerts.length > i + 1) {
              setNextAlert(filteredAlerts[i + 1])
            }
          }
          if (!isCurrentAlertActionDone) {
            alert.action() // Perform the action associated with the alert
            setIsCurrentAlertActionDone(true)
          }
          return // Exit after triggering the latest relevant alert
        }
      }
    }

    // Initial check
    checkTime()
    setCurrentAlert(props.alerts[0])
    const now = new Date().getTime()
    const minutesPassed = (now - props.timeStamp) / (1000 * 60)
    let delay = 2000
    if (nextAlert?.time) {
      delay = nextAlert?.time - minutesPassed
    }
    let intervalId: null | NodeJS.Timeout = null
    // Set up an interval to check every 5 seconds
    if (!allAlertsAlreadyShown) {
      intervalId = setInterval(checkTime, delay)
    }

    // Clean up the interval on component unmount
    return () => {
      if (intervalId !== null) {
        clearInterval(intervalId)
      }
    }
  }, [props.timeStamp,
    props.alerts,
    currentAlert])

  return (
    <div className="d-flex flex-row justify-content-between position-absolute" style={{ zIndex: 10000 }}>
      {currentAlert !== null && showAlert ? (
        <div className="alert alert-warning position-relative">
          <span>{currentAlert.warningText}</span>
          <div style={{ cursor: 'pointer', position: 'absolute', right: '0', top: '0', fontSize: '16px', lineHeight: 1 }} onClick={() => { setShowAlert(false) }}>x</div>
        </div>
      ) : null}
    </div>
  )
}

export default InterviewTimeAlerts
