import Navigation from '../../navigation/Navigation'
import { NavigationModeValues } from '../../modes/NavigationMode'
import React, { createContext, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { getHeaderTextForInterview } from './InterviewPageFunctions'
import InterviewTimeAlerts from '../interviewTimeAlerts'
import {
  GetICUserDtoRoleEnum,
  type GetInterviewQuestionTemplateDto,
  InterviewQuestionControllerApi
} from '../../../api/ic'
import { JWTManager } from '../../../manager/JWTManager'
import { useSelector } from 'react-redux'
import { GetInterviewJWT } from '../../../features/InterviewJWTSlice'
import { GetDeviceTypeInformation } from '../../../features/DeviceTypeInformationSlice'
import { AuthManager } from '../../../manager/AuthManager'
import AgoraRTC, {
  type ICameraVideoTrack,
  type IMicrophoneAudioTrack, type IRemoteAudioTrack,
  LocalVideoTrack, RemoteUser, useClientEvent,
  useJoin,
  useLocalCameraTrack, useLocalMicrophoneTrack, usePublish, useRemoteUsers,
  useRTCClient
} from 'agora-rtc-react'
import VideoMicComponent from './VideoMicComponent'
import { Popover } from '@mui/material'
import SmallDropdown from '../../dropdown/SmallDropdown'
import interview_mic from '../../svg/resources/interview_mic.svg'
import interview_speaker from '../../svg/resources/interview_speaker.svg'
import { type InterviewProcessDTO } from '../../../features/InterviewProcessSlice'
import interview_cam from '../../svg/resources/interview_cam.svg'
import { type InterviewJoinCreds } from '../InterviewProcess/InterviewProcess'
import StandardButton from '../../buttons/StandardButton'
import { NavigationRoute } from '../../../enumeration/NavigationRoute'
import { ButtonModeValues } from '../../modes/ButtonMode'
import { FontModeValues } from '../../modes/FontMode'
import { useNavigate } from 'react-router-dom'
import { createChannel, createClient, type RtmTextMessage, type RtmChannel, type RtmClient } from 'agora-rtm-react'
import InterviewQuestionsTemplate from './InterviewQuestionsTemplate'
import toast from 'react-hot-toast'
import ApiManager from '../../../manager/ApiManager'
import ColoredText from '../../text/ColoredText'
import { receiveMessage, sendMessage } from './MessageHelperFunctions'

// Define the shape of the Agora context
interface AgoraContextType {
  localCameraTrack: ICameraVideoTrack | null
  localMicrophoneTrack: IMicrophoneAudioTrack | null
  children: React.ReactNode
}

// Create the Agora context
const AgoraContext = createContext<AgoraContextType | null>(null)

export const AgoraProvider: React.FC<AgoraContextType> = ({ children, localCameraTrack, localMicrophoneTrack }) => (
  <AgoraContext.Provider value={{ localCameraTrack, localMicrophoneTrack, children }}>
    {children}
  </AgoraContext.Provider>
)

interface InterviewPageProps {
  role: GetICUserDtoRoleEnum
  interviewId: string
  acceptedDate: number
  typeName: string
  interviewPresetSettings: InterviewProcessDTO
  joinCreds: InterviewJoinCreds
}

const popoverStyle = {
  boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.25)',
  backgroundColor: '#3C4043',
  borderRadius: '10px'
}

function InterviewPage (props: InterviewPageProps) {
  // template state
  // rtm events start

  const fetchTemplate = useCallback(async () => {
    await interviewQuestionApi.getTemplate({
      interviewId: props.interviewId
    }).then(res => {
      setTemplate(res.dto)
      setInterviewCompleted(res.completed)
    }).catch(() => {
      // TODO make in a way to not toast the error case
    })
  }, [])

  useEffect(() => {
    void fetchTemplate()
  }, [])

  const fireGenerateEvent = () => {
    if (sessionRef.current) {
      void sendMessage(sessionRef.current, { type: 'templateGenerated', message: {} })
    }
  }
  // rtm events end

  const [interviewCompleted, setInterviewCompleted] = useState<boolean>(false)
  const interviewQuestionApi = ApiManager.getInstance(InterviewQuestionControllerApi)
  const [template, setTemplate] = useState<GetInterviewQuestionTemplateDto | undefined>(undefined)
  const [generateButtonLoading, setGenerateButtonLoading] = useState(false)
  function handleGenerateTemplate () {
    setGenerateButtonLoading(true)
    void interviewQuestionApi.generateTemplate({
      interviewId: props.interviewId
    }).then(res => {
      setTemplate(res)
      fireGenerateEvent()
    }).catch(() => {
      toast.error('Error while generating a template')
    }).finally(() => {
      setGenerateButtonLoading(false)
    })
  }
  // rtm start
  const clientRef = useRef<RtmClient | null>(null)
  const sessionRef = useRef<RtmChannel | null>(null)

  useLayoutEffect(() => {
    const initializeRTMClient = async () => {
      try {
        const useClient = createClient(props.joinCreds.appId)
        const useChannel = createChannel(props.joinCreds.channelName)
        const client = useClient()
        const session = useChannel(client)

        clientRef.current = client
        sessionRef.current = session
        // Log in the client
        await client.login({ uid: props.joinCreds.uid.toString(), token: props.joinCreds.rtm })
        // Join the channel
        await session.join()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        session.on('ChannelMessage', (event: RtmTextMessage) => {
          const message = receiveMessage(event.text)
          if (message?.type === 'templateGenerated') {
            void fetchTemplate()
          }
        }
        )
      } catch (error) {
        console.error('An error occurred during initialization:', error)
      }
    }
    void initializeRTMClient()
    return () => {
      void logout()
    }
  }, [])
  const logout = async () => {
    const client = clientRef.current
    const session = sessionRef.current
    if (session && client) {
      await session.leave()
      await client.logout()
      session.removeAllListeners()
      session.removeAllListeners()
    }
  }
  // rtm end
  AgoraRTC.setLogLevel(4)
  const createCanvasTrack = () => {
    const canvas = document.createElement('canvas')
    canvas.width = 640
    canvas.height = 480
    const context = canvas.getContext('2d')

    const draw = () => {
      if (context) {
        context.clearRect(0, 0, canvas.width, canvas.height)

        // Flip the canvas horizontally
        context.save() // Save the current state
        context.scale(-1, 1) // Flip horizontally
        context.translate(-canvas.width, 0) // Adjust for flipping

        context.fillStyle = 'lightgray'
        context.fillRect(0, 0, canvas.width, canvas.height)

        context.font = '30px Arial'
        context.fillStyle = 'black'
        context.textAlign = 'center' // Center-align the text
        context.fillText('Camera Disabled', canvas.width / 2, canvas.height / 2)

        context.restore() // Restore the state to prevent affecting future drawings
      }
      // Continue drawing to keep the stream alive
      requestAnimationFrame(draw)
    }

    // Start the drawing loop
    draw()

    // Create a video track from the canvas
    return AgoraRTC.createCustomVideoTrack({
      mediaStreamTrack: canvas.captureStream(30).getVideoTracks()[0]
    })
  }
  // INTERVIEW DATA
  const jwt = useSelector(GetInterviewJWT)
  const decryptedJWT = JWTManager.extractPayload(jwt)
  const isMobile = useSelector(GetDeviceTypeInformation).isMobile
  const body = AuthManager.getAccessTokenBody() ?? {}
  const publisherFullName = (body.userId === decryptedJWT?.takerId) ? decryptedJWT?.takerFullname : decryptedJWT?.giverFullname
  const subscriberFullName = (body.userId === decryptedJWT?.takerId) ? decryptedJWT?.giverFullname : decryptedJWT?.takerFullname

  // COMPONENT STATE

  // camera, speaker, mic lists and states
  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([])
  const [mics, setMics] = useState<MediaDeviceInfo[]>([])
  const [speakers, setSpeakers] = useState<MediaDeviceInfo[]>([])
  const [selectedMic, setSelectedMic] = useState<string | undefined>(undefined)
  const [selectedSpeaker, setSelectedSpeaker] = useState<string | undefined>(undefined)
  const [selectedCamera, setSelectedCamera] = useState<string | undefined>(undefined)
  const [cameraDeviceId, setCameraDeviceId] = useState<string | undefined>(props.interviewPresetSettings.selectedCamera ?? undefined)
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const [micDeviceId, setMicDeviceId] = useState<string | undefined>(props.interviewPresetSettings.selectedMicrophone ?? undefined)
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  const [speakerDeviceId, setSpeakerDeviceId] = useState<string | undefined>(props.interviewPresetSettings.selectedAudio ?? undefined)

  // enabled/disabled states
  const [localCameraEnabled, setLocalCameraEnabled] = useState(true)
  const [remoteCameraEnabled, setRemoteCameraEnabled] = useState(true)
  const [localMicEnabled, setLocalMicEnabled] = useState(true)
  const [remoteMicEnabled, setRemoteMicEnabled] = useState(true)

  // Preset Devices
  useEffect(() => {
    void AgoraRTC.getDevices(false).then((devices: MediaDeviceInfo[]) => {
      const initialCamera = devices.filter((device: MediaDeviceInfo) => device.kind === 'videoinput').filter((initialDevice: MediaDeviceInfo) => initialDevice.deviceId === props.interviewPresetSettings.selectedCamera)
      const initialMicro = devices.filter(device => device.kind === 'audioinput').filter((initialDevice: MediaDeviceInfo) => initialDevice.deviceId === props.interviewPresetSettings.selectedMicrophone)
      const initialSpeaker = devices.filter(device => device.kind === 'audiooutput').filter((initialDevice: MediaDeviceInfo) => initialDevice.deviceId === props.interviewPresetSettings.selectedAudio)
      if (initialCamera.length) {
        setSelectedCamera(initialCamera[0].label)
      }
      if (initialMicro.length) {
        setSelectedMic(initialMicro[0].label)
      }
      if (initialSpeaker.length) {
        setSelectedSpeaker(initialSpeaker[0].label)
      }
    })
  }, [])
  // SESSION STATE
  const agoraEngine = useRTCClient()
  const { isLoading: isCameraLoading, localCameraTrack } = useLocalCameraTrack(true, { cameraId: props.interviewPresetSettings.selectedCamera ?? undefined }
  )
  const { isLoading: isMicrophoneLoading, localMicrophoneTrack } = useLocalMicrophoneTrack(true, { microphoneId: props.interviewPresetSettings.selectedMicrophone ?? undefined }
  )
  usePublish([localMicrophoneTrack, localCameraTrack])
  useEffect(() => {
    if (!isMicrophoneLoading && !isCameraLoading) {
      if (!props.interviewPresetSettings.enabledMicrophone && localMicrophoneTrack) {
        void localMicrophoneTrack.setEnabled(false)
        setLocalMicEnabled(false)
      }
      if (!props.interviewPresetSettings.enabledVideo && localCameraTrack) {
        const currentSettings = localCameraTrack.getMediaStreamTrack().getSettings()
        if (currentSettings.deviceId) {
          setCameraDeviceId(currentSettings.deviceId)
        }

        // Create and replace with a canvas track
        const canvasTrack = createCanvasTrack() // Create a canvas track
        void localCameraTrack.replaceTrack(canvasTrack.getMediaStreamTrack(), false) // Replace with canvas without stopping the original track
        setLocalCameraEnabled(false) // Set camera state as disabled
      }
    }
  }, [isCameraLoading, isMicrophoneLoading

  ])
  const remoteUsers = useRemoteUsers()
  const navigate = useNavigate()

  useJoin({
    appid: props.joinCreds.appId,
    channel: props.joinCreds.channelName,
    token: props.joinCreds.rtc,
    uid: props.joinCreds.uid
  })

  useEffect(() => {
    async function fetchDevices () {
      const devices = await AgoraRTC.getDevices(false)
      const upToDateCams = devices.filter((device: MediaDeviceInfo) => device.kind === 'videoinput')
      const upToDateMicros = devices.filter(device => device.kind === 'audioinput')
      const upToDateSpeakers = devices.filter(device => device.kind === 'audiooutput')
      setCameras(upToDateCams)
      setMics(upToDateMicros)
      setSpeakers(upToDateSpeakers)
    }
    void fetchDevices()
    navigator.mediaDevices.ondevicechange = fetchDevices

    // Cleanup listener on component unmount
    return () => {
      navigator.mediaDevices.ondevicechange = null
    }
  }, [])

  //   // Function to change the camera device
  const handleCameraChange = async (deviceId: string) => {
    if (localCameraTrack) {
      await localCameraTrack.setDevice(deviceId)
      setCameraDeviceId(deviceId)
    }
  }

  // Function to change the microphone device
  const handleMicChange = async (deviceId: string) => {
    if (localMicrophoneTrack) {
      await localMicrophoneTrack.setDevice(deviceId)
      setMicDeviceId(deviceId)
    }
  }

  // Function to enable/disable the camera and replace it with canvas
  const toggleCamera = async () => {
    if (localCameraTrack) {
      if (localCameraEnabled) {
        // Store the current camera device ID before disabling
        const currentSettings = localCameraTrack.getMediaStreamTrack().getSettings()
        if (currentSettings.deviceId) {
          setCameraDeviceId(currentSettings.deviceId)
        }

        // Create and replace with a canvas track
        const canvasTrack = createCanvasTrack() // Create a canvas track
        await localCameraTrack.replaceTrack(canvasTrack.getMediaStreamTrack(), false) // Replace with canvas without stopping the original track
        setLocalCameraEnabled(false) // Set camera state as disabled
      } else {
        // Re-enable the original camera
        if (cameraDeviceId) {
          await localCameraTrack.setDevice(cameraDeviceId) // Reapply the original camera device
          setLocalCameraEnabled(true) // Set camera state as enabled
        }
      }
    }
  }

  // Function to change the speaker device (audio output)
  const handleSpeakerChange = async (deviceId: string) => {
    setSpeakerDeviceId(deviceId)
    remoteUsers.forEach((remoteUser) => {
      const audioTrack = remoteUser.audioTrack as IRemoteAudioTrack
      if (audioTrack) {
        void audioTrack.setPlaybackDevice(deviceId)
      }
    })
  }
  // device selection and enable/disable
  const toggleMicrophone = async () => {
    if (localMicrophoneTrack) {
      if (localMicEnabled) {
        await localMicrophoneTrack.setEnabled(false)
        setLocalMicEnabled(false)
      } else {
        await localMicrophoneTrack.setEnabled(true)
        setLocalMicEnabled(true)
      }
    }
  }
  // MICROPHONE
  const [microphoneListAnchor, setMicrophoneListAnchor] = React.useState<HTMLButtonElement | null>(null)

  const handleMicrophoneListClick = (event: any) => {
    setMicrophoneListAnchor(event.currentTarget)
  }
  const handleMicrophoneListClose = () => {
    setMicrophoneListAnchor(null)
  }
  const microphoneListOpen = Boolean(microphoneListAnchor)
  const microphoneId = microphoneListOpen ? 'open-microphone-list' : undefined

  // VIDEO
  const [videoListAnchor, setVideoListAnchor] = React.useState<HTMLButtonElement | null>(null)

  const handleVideoListClick = (event: any) => {
    setVideoListAnchor(event.currentTarget)
  }
  const handleVideoListClose = () => {
    setVideoListAnchor(null)
  }
  const videoListOpen = Boolean(videoListAnchor)
  const videoId = videoListOpen ? 'open-video-list' : undefined

  // Listen for user events
  useClientEvent(agoraEngine, 'user-published', (user, mediaType) => {
    if (mediaType === 'video') {
      setRemoteCameraEnabled(true)
    } else if (mediaType === 'audio') {
      setRemoteMicEnabled(true)
    }
  })

  useClientEvent(agoraEngine, 'user-unpublished', (user, mediaType) => {
    if (mediaType === 'video') {
      setRemoteCameraEnabled(false)
    } else if (mediaType === 'audio') {
      setRemoteMicEnabled(false)
    }
  })

  return (
    <AgoraProvider localCameraTrack={localCameraTrack} localMicrophoneTrack={localMicrophoneTrack}>
      <Navigation
        navigationMode={NavigationModeValues.FIXED_WHITE_WITH_INTERVIEW_INFO}
        interviewText={getHeaderTextForInterview(props.acceptedDate, props.typeName)}
        mobileNavItems={[]}
        height={'60px'}/>
      <div className={'w-100 d-flex flex-row justify-content-center'} style={{ zIndex: '1000' }}>
        <InterviewTimeAlerts timeStamp={props.acceptedDate ?? 0}
          alerts={[
            {
              time: 30,
              warningText: '30 minutes that interview have started',
              action: () => {
              }
            },
            {
              time: 50,
              warningText: '10 minutes remaining',
              action: () => {
              }
            }
          ]}
        />
      </div>
      {/* MAIN BODY */}
      <div
        className={`d-flex flex-row flex-wrap align-items-${!template ? 'center' : 'end'} justify-content-evenly`}
        style={{
          flexWrap: 'wrap-reverse',
          animationDuration: '1.5s',
          paddingTop: '80px'
        }}>
        <div className={`d-flex ${isMobile ? 'flex-column gap-2' : 'flex-row gap-5'} justify-content-center align-items-center`}>
          <div // template
            className={'position-relative'}>
            {!interviewCompleted && template
              ? <InterviewQuestionsTemplate
                  subscriber={remoteUsers.length ? remoteUsers[0] : null }
                  client={clientRef.current}
                  session={sessionRef.current}
                  template={template}
                  interviewCompleted={interviewCompleted}
                  setInterviewCompleted={setInterviewCompleted}
                  type={props.role}/>
              : props.role === GetICUserDtoRoleEnum.Taker && !interviewCompleted
                ? <StandardButton
                    text={'Generate Template To Start'}
                    backgroundColor={'white'}
                    isLoading={generateButtonLoading}
                    clickable={remoteUsers.length > 0}
                    onClick={handleGenerateTemplate}
                    textColor={'black'}
                    sizeMode={ButtonModeValues.LARGE}/>
                : null}
            {interviewCompleted && <div>
              <ColoredText
                singleText={'You are done! The recording is saved!'}
                color={'white'}/>
            </div>}
          </div>
          <div // videos
            className='d-flex flex-column justify-content-evenly gap-3'>
            <div className={'d-flex justify-content-center align-items-center'}
              style={{
                width: isMobile ? '400px' : '450px',
                height: isMobile ? '250px' : '250px',
                backgroundColor: '#2E302F',
                borderRadius: '10px',
                position: 'relative'
              }}>
              <span style={{
                position: 'absolute',
                bottom: '8%',
                left: '4%',
                zIndex: 1000,
                color: 'white',
                fontWeight: 'bold',
                fontSize: '14px'
              }}>
                {publisherFullName}
              </span>
              {!isCameraLoading && !isMicrophoneLoading && (
              <LocalVideoTrack
                play={true}
                track={localCameraTrack}/>
              )}
            </div>
            <div
              style={{
                width: isMobile ? '400px' : '450px',
                height: isMobile ? '250px' : '250px',
                backgroundColor: '#2E302F',
                borderRadius: '10px',
                position: 'relative'
              }}
              className={'d-flex justify-content-center align-items-center'}>
              {remoteUsers.length > 0 && (
              <RemoteUser
                user={remoteUsers[0]}
                playVideo={remoteCameraEnabled}
                playAudio={remoteMicEnabled}
                key={remoteUsers[0].uid}
                style={{ transform: 'scaleX(-1)' }}
                  />
              )}
              <span style={{
                position: 'absolute',
                bottom: '8%',
                left: '4%',
                zIndex: 1000,
                color: 'white',
                fontWeight: 'bold',
                fontSize: '14px'
              }}>
                {subscriberFullName}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={'d-flex flex-row gap-3 pt-4 justify-content-center'}>
        <div className={'d-flex flex-row align-items-start'}>
          <VideoMicComponent
            byDefaultEnabled={props.interviewPresetSettings.enabledMicrophone}
            mode={'mic'}
            click={toggleMicrophone}
            arrowClick={handleMicrophoneListClick}/>
          <div>
            <Popover
              id={microphoneId}
              disableRestoreFocus
              open={microphoneListOpen}
              anchorEl={microphoneListAnchor}
              slotProps={{
                paper: {
                  sx: {
                    ...popoverStyle
                  }
                }
              }}
              onClose={handleMicrophoneListClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}>
              <div className={'d-flex flex-row gap-2 p-3'}>
                <SmallDropdown
                  onChange={(val: string | number) => {
                    void AgoraRTC.getMicrophones(true).then((mics: MediaDeviceInfo[]) => {
                      const foundMic = mics.filter((mic: MediaDeviceInfo) => mic.label === val)
                      if (foundMic.length > 0) {
                        void handleMicChange(foundMic[0].deviceId)
                      }
                    })
                  }}
                  leftIcon={interview_mic}
                  leftIconHeight={'25px'}
                  leftIconWidth={'25px'}
                  fontColor='white'
                  logoMode='light'
                  backgroundColor={'#565A5D'}
                  borderRadius={'20px'}
                  items={mics.map(micro => micro.label)}
                  selected={selectedMic}
                  setIsSelected={setSelectedMic}
                  eachItemStyles={{
                    color: 'white'
                  }}/>
                <SmallDropdown
                  onChange={(val: string | number) => {
                    const foundSpeaker = speakers.filter((speaker: MediaDeviceInfo) => speaker.label === val)
                    if (foundSpeaker.length > 0) {
                      void handleSpeakerChange(foundSpeaker[0].deviceId)
                    }
                  }}
                  leftIcon={interview_speaker}
                  leftIconHeight={'25px'}
                  leftIconWidth={'25px'}
                  fontColor='white'
                  logoMode='light'
                  backgroundColor={'#565A5D'}
                  borderRadius={'20px'}
                  items={speakers.map(speaker => speaker.label)}
                  selected={selectedSpeaker}
                  setIsSelected={setSelectedSpeaker}
                  eachItemStyles={{
                    color: 'white'
                  }}/>
              </div>
            </Popover>
          </div>
        </div>
        <div className={'d-flex flex-row align-items-start'}>
          <VideoMicComponent
            byDefaultEnabled={props.interviewPresetSettings.enabledVideo}
            mode={'video'}
            click={async () => {
              await toggleCamera()
            }}
            arrowClick={handleVideoListClick}/>
          <Popover
            id={videoId}
            disableRestoreFocus
            open={videoListOpen}
            anchorEl={videoListAnchor}
            slotProps={{
              paper: {
                sx: {
                  ...popoverStyle
                }
              }
            }}
            onClose={handleVideoListClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}>
            <div className={'d-flex flex-row p-3'}>
              <SmallDropdown onChange={(val: string | number) => {
                void AgoraRTC.getCameras(true).then((cams: MediaDeviceInfo[]) => {
                  const foundCam = cams.filter((mic: MediaDeviceInfo) => mic.label === val)
                  if (foundCam.length > 0) {
                    void handleCameraChange(foundCam[0].deviceId)
                  }
                })
              }
                    }
                items={cameras.map(video => video.label)}
                leftIcon={interview_cam}
                leftIconHeight={'25px'}
                leftIconWidth={'25px'}
                fontColor='white'
                logoMode='light'
                backgroundColor={'#565A5D'}
                borderRadius={'20px'}
                selected={selectedCamera}
                setIsSelected={setSelectedCamera}
                eachItemStyles={{
                  color: 'white'
                }}/>
            </div>
          </Popover>
        </div>
        <StandardButton
          text='Leave interview'
          style={{
            background: 'red',
            whiteSpace: 'nowrap'
          }}
          onClick={() => {
            navigate(NavigationRoute.INTERVIEW_FEEDBACK.replace('/:id/', `/${props.interviewId}/`), { replace: true })
          }}
          textColor='white'
          sizeMode={ButtonModeValues.DEFAULT}
          textFontSize={FontModeValues.SMALL}/>
      </div>
    </AgoraProvider>
  )
}

export default InterviewPage
