/* eslint-disable */
/**
 * API Documentation
 * The API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InterviewJoiningResponseDto
 */
export interface InterviewJoiningResponseDto {
    /**
     * Encoded token with all joining information
     * @type {string}
     * @memberof InterviewJoiningResponseDto
     */
    token: string;
    /**
     * Encoded agent token with all joining information
     * @type {string}
     * @memberof InterviewJoiningResponseDto
     */
    agentToken: string;
}

/**
 * Check if a given object implements the InterviewJoiningResponseDto interface.
 */
export function instanceOfInterviewJoiningResponseDto(value: object): value is InterviewJoiningResponseDto {
    if (!('token' in value) || value['token'] === undefined) return false;
    if (!('agentToken' in value) || value['agentToken'] === undefined) return false;
    return true;
}

export function InterviewJoiningResponseDtoFromJSON(json: any): InterviewJoiningResponseDto {
    return InterviewJoiningResponseDtoFromJSONTyped(json, false);
}

export function InterviewJoiningResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewJoiningResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'token': json['token'],
        'agentToken': json['agentToken'],
    };
}

export function InterviewJoiningResponseDtoToJSON(value?: InterviewJoiningResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'token': value['token'],
        'agentToken': value['agentToken'],
    };
}

