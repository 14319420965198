import React, { type ReactElement, useState, useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { AuthManager } from '../manager/AuthManager'
import { NavigationRoute } from '../enumeration/NavigationRoute'
import { VerificationRequestDtoTypeEnum as AuthRole } from '../api/ic'

interface AuthProps {
  allowedRoles: string[]
  include?: string[]
  exclude?: string[]
  additionalComponent?: ReactElement
}

const Auth: React.FC<AuthProps> = ({ allowedRoles, include, exclude, additionalComponent }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [userRole, setUserRole] = useState<string | null>()
  const location = useLocation()
  const pathname = window.location.pathname

  useEffect(() => {
    const checkUserRole = async () => {
      const currentUserRole = AuthManager.getUserRole()
      if (!currentUserRole) {
        await AuthManager.refreshTokenIfNeeded()
          .then(res => {
            if (res) {
              AuthManager.setTokens(res)
              setUserRole(AuthManager.getUserRole())
            } else {
              setUserRole(AuthRole.Anonymous)
            }
          }).catch(() => {})
      } else {
        setUserRole(currentUserRole)
      }
      setIsLoading(false)
    }

    void checkUserRole()
  }, [userRole, location])

  if (isLoading) {
    return <></>
  }

  if (allowedRoles.find((role) => userRole?.includes(role))) {
    if (
      (include && include.some((path) => pathname.startsWith(path))) ||
      (exclude && !exclude.some((path) => pathname.startsWith(path)))
    ) {
      return (
        <>
          <Outlet />
          {additionalComponent}
        </>
      )
    } else {
      return <Outlet />
    }
  }
  if (userRole === AuthRole.Anonymous) {
    return (
      <Navigate to={NavigationRoute.LOG_IN}/>
    )
  }

  return (
    userRole === AuthRole.Admin
      ? <Navigate to={NavigationRoute.ADMIN}/>
      : <Navigate to={NavigationRoute.HOME_PAGE}/>
  )
}

export default Auth
