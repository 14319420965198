/* eslint-disable */
/**
 * API Documentation
 * The API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InterviewJoiningRequestDto
 */
export interface InterviewJoiningRequestDto {
    /**
     * Signed Interview bearer token
     * @type {string}
     * @memberof InterviewJoiningRequestDto
     */
    bearerToken: string;
}

/**
 * Check if a given object implements the InterviewJoiningRequestDto interface.
 */
export function instanceOfInterviewJoiningRequestDto(value: object): value is InterviewJoiningRequestDto {
    if (!('bearerToken' in value) || value['bearerToken'] === undefined) return false;
    return true;
}

export function InterviewJoiningRequestDtoFromJSON(json: any): InterviewJoiningRequestDto {
    return InterviewJoiningRequestDtoFromJSONTyped(json, false);
}

export function InterviewJoiningRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewJoiningRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'bearerToken': json['bearerToken'],
    };
}

export function InterviewJoiningRequestDtoToJSON(value?: InterviewJoiningRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'bearerToken': value['bearerToken'],
    };
}

